.meses-table {
    width: 100%;
}

.meses-table td {
    border: 1px solid #EBEBEB;
}

.meses-table > tbody > tr > td {
    padding: 5px;
}

.meses-table > tbody > tr > td {
    text-align: center;
}

.meses-table > tbody > tr.red-bg {
    background-color: #FFFAF3;
}

.meses-table > tbody > tr.green-bg {
    background-color: #F3FFFB;
}

.meses-table > tbody > tr.blue-bg {
    background-color: #F3FCFF;
}

.meses-table > tbody > tr.yellow-bg {
    background-color: #FCFFF3;
}

.meses-table input {
    font-size: 16px;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: center;
}

.meses-table input:focus {
    outline: 1px solid #ccc;
    border: 1px solid #ccc;
    background-color: #FFF;
}

.meses-table input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.meses-table input[type=number] {
  -moz-appearance: textfield;
}