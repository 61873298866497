.almacen-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 1em;
}

.almacen-table input {
    font-size: 16px;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: center;
}

.almacen-table input:focus {
    outline: 1px solid #ccc;
    border: 1px solid #ccc;
    background-color: #FFF;
}

.almacen-table input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.almacen-table input[type=number] {
  -moz-appearance: textfield;
}


.almacen-table .total-unidades td {
  background-color: #FFFAF3;
}