.custom-file-picker {
    width: 225px;
    height: 225px;
    position: relative;
    border: 1px solid var(--greyLight);
    border-radius: 5px;
    cursor: pointer;
}

.custom-file-picker input[type="file"] {
    display: none;
}

.custom-file-picker-icon {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: var(--standard);
    position: absolute;
    right: -12px;
    bottom: -12px;
}


.image-picker-image {
    border-radius:5px;
}